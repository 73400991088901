const sizes = {
  header: {
    headerHeight: "96px",
    paddingHeader: "0px 96px",
  },
  button: {
    rounded: {
      sm: {
        width: 124,
        height: 40,
        borderRadius: 30,
      },
      lg: {
        width: 400,
        height: 50,
        borderRadius: 50,
      },
    },
    circle: {
      width: 40,
      height: 40,
      borderRadius: 50,
    },
  },
  images: {
    logo_sm: {
      width: 66,
      height: 36,
    },
    logo_bg: {
      width: 66,
      height: 66,
    },
  },
}
export default sizes
