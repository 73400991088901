import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Layout/Layout.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/BMWTypeNextProHebrew-Medium.otf\",\"weight\":\"500\"},{\"path\":\"../fonts/BMWTypeNextProHebrew-Bold.otf\",\"weight\":\"bold\"},{\"path\":\"../fonts/BMWTypeNextProHebrew-Regular.otf\",\"weight\":\"normal\"},{\"path\":\"../fonts/BMWTypeNextProHebrew-Thin.otf\",\"weight\":\"100\"}],\"variable\":\"--bmw-next\"}],\"variableName\":\"bmwNext\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/theme/ThemeProviders.tsx");
