"use client"

import styled from "@emotion/styled"
import { FC, useRef } from "react"
import "swiper/css"
import "swiper/css/pagination"
import Footer from "./Footer"
import Header from "./Header"

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;

  :focus {
    outline: 0;
  }
`

const Layout: FC<any> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
        <Header />

        {children}
      </StyledMain>
      <Footer />
    </>
  )
}

export default Layout
