import { Box } from "@mui/material"
import Image from "next/image"

const socialLinks = [
  { link: "https://www.facebook.com/bmwisraelofficial", icon: "/facebook.svg" },
  {
    link: "https://www.instagram.com/bmwisraelofficial/",
    icon: "/InstagramLogo.svg",
  },
  {
    link: "https://www.youtube.com/channel/UCgzed63WhacbDhncH9GwvYA",
    icon: "/youtube.svg",
  },
]
const SocialLinks = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        display: "flex",
        gap: "10px",
        padding: "24px 0px 32px",
        justifyContent: "center",
      }}
    >
      {socialLinks.map(({ link, icon }) => (
        <a
          rel="noreferrer noopener"
          style={{ textDecoration: "none", color: "black" }}
          key={link}
          href={link}
        >
          <Image src={icon} alt={link} width={24} height={24} />
        </a>
      ))}
    </Box>
  )
}

export default SocialLinks
