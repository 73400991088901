const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fi-rr-cross">
        <path
          id="Vector"
          d="M21.3662 2.63423C21.2161 2.48426 21.0127 2.4 20.8005 2.4C20.5884 2.4 20.385 2.48426 20.2349 2.63423L12.0004 10.8688L3.76584 2.63423C3.61582 2.48426 3.41237 2.4 3.20023 2.4C2.9881 2.4 2.78465 2.48426 2.63462 2.63423C2.48464 2.78426 2.40039 2.98771 2.40039 3.19984C2.40039 3.41198 2.48464 3.61543 2.63462 3.76546L10.8692 12L2.63462 20.2345C2.48464 20.3846 2.40039 20.588 2.40039 20.8002C2.40039 21.0123 2.48464 21.2157 2.63462 21.3658C2.78465 21.5157 2.9881 21.6 3.20023 21.6C3.41237 21.6 3.61582 21.5157 3.76584 21.3658L12.0004 13.1312L20.2349 21.3658C20.385 21.5157 20.5884 21.6 20.8005 21.6C21.0127 21.6 21.2161 21.5157 21.3662 21.3658C21.5161 21.2157 21.6004 21.0123 21.6004 20.8002C21.6004 20.588 21.5161 20.3846 21.3662 20.2345L13.1316 12L21.3662 3.76546C21.5161 3.61543 21.6004 3.41198 21.6004 3.19984C21.6004 2.98771 21.5161 2.78426 21.3662 2.63423Z"
          fill="#535353"
        />
      </g>
    </svg>
  )
}

export default CloseIcon
